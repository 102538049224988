<!--
程序版本：V2.0
版权所有：深圳市科飞时速网络科技有限公司
技术支持：Tech@21gmail.com
单元名称：API配置
开始时间：2021-09-22
开发人员：康泽鑫
最后修改：2021-09-22
备注说明：如需修改请联系开发人员
-->
<template>
  <div class="api_config" id="api_config">
    <div class="operationList">
      <!-- api配置操作框 -->
      <div class="billingview_headoperate">
        <!-- 快捷查询 -->
        <div class="head_operateList" style="margin-left: 10px;">
          <ul class="list">
            <li @click="getApiList">
              全部
            </li>
            <li>
              <el-dropdown trigger="click" @command="command($event,0)">
                <span class="el-dropdown-link">
                  接口类型<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1">文档转换</el-dropdown-item>
                  <el-dropdown-item :command="2">文档扫描</el-dropdown-item>
                  <el-dropdown-item :command="3">图片识别</el-dropdown-item>
                  <el-dropdown-item :command="4">智能翻译</el-dropdown-item>
                  <el-dropdown-item :command="5">短信</el-dropdown-item>
                  <el-dropdown-item :command="6">微信</el-dropdown-item>
                  <el-dropdown-item :command="7">邮件</el-dropdown-item>
                  <el-dropdown-item :command="8">EDM</el-dropdown-item>
                  <el-dropdown-item :command="9">企业微信</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li>
              <el-dropdown trigger="click" @command="command($event,1)">
                <span class="el-dropdown-link">
                  接口服务商<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="度慧PDF API">度慧PDF API</el-dropdown-item>
                  <el-dropdown-item command="奇伢PDF API">奇伢PDF API</el-dropdown-item>
                  <el-dropdown-item command="WPS文档转换">WPS文档转换</el-dropdown-item>
                  <el-dropdown-item command="企业微信消息推送">企业微信消息推送</el-dropdown-item>
                  <el-dropdown-item command="企业微信通讯录管理">企业微信通讯录管理</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
        </div>
        <!-- 操作 -->
        <div class="head_operateList" style="margin-right: 10px;">
          <ul class="list">
            <li @click="showOperateStateBox">
              <i class="el-icon-plus"></i>新增
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="billingview_tablewrapper billing_table" style="height: 86vh">
      <el-table id="api_table" :data="apiData" height="100%" border style="width: 100%">
        <!-- 数据内容 -->
        <el-table-column label="" fixed width="80">
          <template slot-scope="scope">
            <!-- 文档转换 -->
            <div v-if="scope.row.apiType == 1 || scope.row.apiType == 3">
              <input class="radio" type="radio" :name="scope.row.apiType+''"
                v-model="scope.row.isEnable" value="1" @click="controlEnable(scope.row)"
                :disabled="isDisabled"><label>启用</label>
            </div>
            <!-- 企业微信 -->
            <div v-if="scope.row.apiType == 9">
              <label>启用</label>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" title="修改" @click="showOperateStateBox(scope.row,1)"></i>
            <i class="el-icon-delete icon" title="删除" @click="delectApi(scope.row)"></i>
          </template>
        </el-table-column>
        <el-table-column sortable label="接口类型" width="220">
          <template slot-scope="scope">
            <span v-if="scope.row.apiType==1">文档转换</span>
            <span v-if="scope.row.apiType==2">文档扫描</span>
            <span v-if="scope.row.apiType==3">图片识别</span>
            <span v-if="scope.row.apiType==4">智能翻译</span>
            <span v-if="scope.row.apiType==5">短信</span>
            <span v-if="scope.row.apiType==6">微信</span>
            <span v-if="scope.row.apiType==7">邮件</span>
            <span v-if="scope.row.apiType==8">EDM</span>
            <span v-if="scope.row.apiType==9">企业微信</span>
          </template>
        </el-table-column>
        <el-table-column prop="apiName" label="接口服务商" width="220"></el-table-column>
        <el-table-column prop="apiId" label="用户名" show-overflow-tooltip width="200" :formatter="formatterCellval">
        </el-table-column>
        <el-table-column label="密码" show-overflow-tooltip width="100" :formatter="formatterCellval">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.apiKey" placement="top">
              <span style="cursor: pointer;">******</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="apiCode" label="CODE" show-overflow-tooltip width="150" :formatter="formatterCellval">
        </el-table-column>
        <el-table-column prop="apiUrl" label="链接" show-overflow-tooltip min-width="150" :formatter="formatterCellval">
        </el-table-column>
        <el-table-column prop="apiTest" label="附加" show-overflow-tooltip min-width="150" :formatter="formatterCellval">
        </el-table-column>
        <el-table-column prop="remarks" label="备注" show-overflow-tooltip min-width="150" :formatter="formatterCellval">
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增修改api配置弹框 -->
    <el-dialog :title="(operateState == 0 ?'新增':'修改') + 'API配置'" :visible.sync="show_operateStateBox"
      :before-close="closeOperateStateBox" width="450px" append-to-body>
      <!-- 弹框内容-->
      <el-form :model="apiConfig" ref="apiConfigForm" size="mini" label-width="100px">
        <el-form-item label="接口类型:" required>
          <el-select v-model="apiConfig.interfaceType" placeholder="请选择接口类型" style="width: 85%;">
            <el-option label="文档转换" :value="1">文档转换</el-option>
            <el-option label="文档扫描" :value="2">文档扫描</el-option>
            <el-option label="图片识别" :value="3">图片识别</el-option>
            <el-option label="智能翻译" :value="4">智能翻译</el-option>
            <el-option label="短信" :value="5">短信</el-option>
            <el-option label="微信" :value="6">微信</el-option>
            <el-option label="邮件" :value="7">邮件</el-option>
            <el-option label="EDM" :value="8">EDM</el-option>
            <el-option label="企业微信" :value="9">企业微信</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="接口服务商:" required>
          <el-select v-model="apiConfig.interfaceService" placeholder="请选择接口服务商" style="width: 85%;">
            <el-option label="度慧PDF API" value="度慧PDF API">度慧PDF API</el-option>
            <el-option label="奇伢PDF API" value="奇伢PDF API">奇伢PDF API</el-option>
            <el-option label="WPS文档转换" value="WPS文档转换">WPS文档转换</el-option>
            <el-option label="企业微信消息推送" value="企业微信消息推送">企业微信消息推送</el-option>
            <el-option label="企业微信通讯录管理" value="企业微信通讯录管理">企业微信通讯录管理</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名:" prop="username" :rules="[{ required: true, message: '请输入用户名', trigger: 'blur' }]">
          <el-input v-model="apiConfig.username" size="mini" placeholder="请输入用户名" style="width: 85%;" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="密码:">
          <el-input v-model="apiConfig.password" size="mini" placeholder="请输入密码" style="width: 85%;" clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="CODE:">
          <el-input v-model="apiConfig.code" size="mini" placeholder="请输入CODE" style="width: 85%;" clearable></el-input>
        </el-form-item>
        <el-form-item label="链接:">
          <el-input v-model="apiConfig.link" size="mini" placeholder="请输入链接" style="width: 85%;" clearable></el-input>
        </el-form-item>
        <el-form-item label="附加:">
          <el-input v-model="apiConfig.additional" type="textarea" size="mini" placeholder="请输入附加内容" style="width: 85%;"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input v-model="apiConfig.remark" type="textarea" size="mini" placeholder="请输入备注" style="width: 85%;"
            clearable></el-input>
        </el-form-item>
      </el-form>
      <!-- 弹框操作按钮-->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeOperateStateBox">取 消</el-button>
        <el-button size="small" type="primary" @click="operateApiConfig">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入方法
  import api from '@/network/system/basicsetup'

  export default {
    name: 'api_config',
    data() {
      return {
        isDisabled: true, //启用按钮是否禁用

        apiData: [], //api配置列表数据

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 0, //总页数
          pageSize: 20 //页面大小
        },

        //表单数据
        apiConfig: {
          interfaceType: 1, //接口类型
          interfaceService: '度慧PDF API', //接口服务商
          username: '', //用户名
          password: '', //密码
          code: '', //CODE
          link: '', //链接
          additional: '', //附加
          remark: '', //备注
        },

        //辅助数据
        loading: '', //loading框
        operateState: 0, //修改或者增加标记
        show_operateStateBox: false, //是否显示新增修改弹框
        searchType: '', //储存条件查询类型(0=>接口类型 1=>接口服务商)
        commandVal: '', //储存条件查询的条件
      }
    },
    computed: {
      ...mapState({
        userPowerInfo: state => state.user.userPowerInfo, //权限
      }),
    },
    created() {},
    mounted() {
      this.getApiList() //获取列表信息
    },

    methods: {
      ...mapActions([
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 查询api配置列表 */
      async getApiList(command, type) {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 11, 1, '查询')) {
            this.apiData = []
            return
          }
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 11, 1, '查询')) {
            this.apiData = []
            return
          }
        }
        this.commandVal = command
        this.searchType = type
        let data = {
          apiType: '',
          apiName: ''
        }
        if (type == 0) { //接口类型
          data.apiType = command
        }
        if (type == 1) { //接口服务商
          data.apiName = command
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#api_config", 4, 'api配置列表信息获取中,请稍后...')
        api.findAllApiConfig(data).then(res => {
          //关闭loading框
          this.loading.close()
          if (res.code == 200) {
            this.apiData = res.data
            //判断是否有操作权限
            if (this.commonJsExtend.isHaveThePower(22, 11, 4, '操作', 0)) {
              return
            }

            this.isDisabled = false;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 新增修改api配置 */
      operateApiConfig() {
        //表单校验
        this.$refs.apiConfigForm.validate((valid) => {
          if (valid) {
            this.addUpdateApiConfig() //请求接口
            this.closeOperateStateBox() //关闭弹框
          } else {
            return false;
          }
        });
      },

      /* 新增修改api配置 */
      addUpdateApiConfig() {
        let {
          interfaceType, //接口类型
          interfaceService, //接口服务商
          username, //用户名
          password, //密码
          code, //CODE
          link, //链接
          additional, //附加
          remark, //备注
        } = this.apiConfig //表单数据
        let data = {
          apiId: username, //用户名
          apiKey: password, //密码
          apiType: interfaceType, //接口类型
          apiName: interfaceService, //接口服务商
          apiCode: code, //CODE
          apiUrl: link, //链接
          apiTest: additional, //附加
          remarks: remark //备注
        }
        if (this.operateState == 0) { //新增
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#api_config", 4, 'api配置信息新增中,请稍后...')
          //发送请求
          api.addApiConfig(data).then(res => {
            //关闭loading框
            this.loading.close()
            if (res.code == 200) {
              this.getApiList(this.command, this.searchType) //获取列表
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else { //修改
          data.id = this.apiConfig.id
          data.newName = this.apiConfig.newName
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#api_config", 4, 'api配置信息修改中,请稍后...')
          //发送请求
          api.updateApiConfig(data).then(res => {
            //关闭loading框
            this.loading.close()
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '修改成功!',
                duration: this.elDuration
              })
              this.getApiList(this.command, this.searchType) //获取列表
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 删除api配置 */
      delectApi(data) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 11, 3, '删除')) {
          return
        }
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#api_config", 4, 'api配置信息删除中,请稍后...') //加载loading框
          api.deleteApiConfig({
            id: data.id
          }).then(res => {
            this.loading.close() //关闭loading框
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功!',
                duration: this.elDuration
              })
              this.getApiList() //获取列表
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },

      /* 控制是否启用 */
      controlEnable(data) {
        data.newName = data.apiName
        data.isEnable = 1
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#api_config", 4, 'api配置信息启用中,请稍后...')
        //发送请求
        api.updateApiConfig(data).then(res => {
          //关闭loading框
          this.loading.close()
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '已启用!',
              duration: this.elDuration
            })
            this.getApiList(this.command, this.searchType) //获取列表
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 根据接口类型、服务商查询 */
      command(command, type) {
        this.getApiList(command, type) //查询api配置列表
      },

      /* 打开弹框 type => 1:修改*/
      showOperateStateBox(data, type) {
        this.operateState = 0
        if (type == 1) {
          //判断是否有修改权限
          if (this.commonJsExtend.isHaveThePower(22, 11, 4, '操作')) {
            return
          }
          this.operateState = type //修改
          this.apiConfig = {
            username: data.apiId,
            password: data.apiKey,
            interfaceType: data.apiType,
            interfaceService: data.apiName,
            code: data.apiCode,
            link: data.apiUrl,
            additional: data.apiTest,
            remark: data.remarks,
            id: data.id,
            newName: data.apiName
          }
        } else {
          //判断是否有新增权限
          if (this.commonJsExtend.isHaveThePower(22, 11, 2, '新增')) {
            return
          }
        }
        this.show_operateStateBox = true //打开弹框
      },

      /* 关闭弹框 */
      closeOperateStateBox() {
        this.$refs.apiConfigForm.resetFields() //清空表单校验
        Object.assign(this.$data.apiConfig, this.$options.data().apiConfig) //初始化apiConfig数据
        this.show_operateStateBox = false //关闭弹框
      },

      /* 无内容显示占位符 */
      formatterCellval(row, column, cellValue, index) {
        if (!Boolean(cellValue)) {
          return "-";
        } else {
          return cellValue;
        }
      },
    }
  }
</script>

<style lang="less" scoped>
  /* 启用按钮 */
  .radio {
    width: 17px;
    height: 18px;
    appearance: none;
    position: relative;
    cursor: pointer;

    &+label {
      // cursor: pointer;
    }

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border: 1px solid #dcdfe6;
      display: inline-block;
      border-radius: 50%;
      vertical-align: middle;
    }
  }

  /* 启用按钮被选中时 */
  .radio:checked {
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      border: 1px solid #409eff;
      background: #409eff;
      display: inline-block;
      border-radius: 50%;
      vertical-align: middle;
    }

    &:after {
      content: '';
      width: 6px;
      height: 3px;
      border: 2px solid white;
      border-top: transparent;
      border-right: transparent;
      text-align: center;
      display: block;
      position: absolute;
      top: 5px;
      left: 3px;
      vertical-align: middle;
      transform: rotate(-45deg);
    }

    &+label {
      color: #409eff;
    }
  }
</style>
